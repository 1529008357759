import React from 'react'
import Layout from '../../components/Layout'
import './Pricing.scss'
import HeroBanner from '../../components/HeroBanner'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { graphql } from 'gatsby'

const Pricing = ({ data }) => {
  return (
    <Layout>
      <div className="Pricing">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Our Pricing"
          caption="A Healthy You? Priceless"
          actionURL="/schedule"
          actionText="Register Now"
        />
        <div className="container content">
          <h2>Just dropping by?</h2>
          <ListGroup>
            <ListGroupItem>$20 - SINGLE DROP IN</ListGroupItem>
            <ListGroupItem>
              ​$45 - FAMILY PASS | ONE PARENT AND 2 CHILDREN - MUST BE UNDER 16
              YEARS OF AGE
            </ListGroupItem>
          </ListGroup>
          <h2>
            Class Packs
            <sub>(Can be used for open gym or adult classes)</sub>
          </h2>
          <ListGroup>
            <ListGroupItem>$75 - 5 PACK</ListGroupItem>
            <ListGroupItem>$140 - 10 PACK</ListGroupItem>
            <ListGroupItem>​$260 - 20 PACK</ListGroupItem>
          </ListGroup>
          <h2>
            Monthly membership
            <sub>
              This membership can be used for open gym, adult classes. This will
              get you unlimited access to the facility. Membership includes an
              Assessment with our in house nutritionist ($60 value) and a 30
              minute consultation with a personal trainer, unlimited access to
              ObstacleFit classes, Boot-camps, Open Gym time, Century RX and
              Inferno classes.
            </sub>
          </h2>
          <ListGroup>
            <ListGroupItem>
              1 Day Adult Membership - $60.00 per month (access to one adult
              class per week, does not include open gym)
            </ListGroupItem>
            <ListGroupItem>
              OCRA Unlimited Monthly Membership - $120 on a 6 month term (plus
              taxes)
            </ListGroupItem>
            <ListGroupItem>
              OCRA Unlimited Month to Month Membership - $120 (plus taxes)
            </ListGroupItem>
          </ListGroup>
          <h4>Obstacle Fit Kids</h4>
          <ListGroup>
            <ListGroupItem>
              1 Day OFK Membership - $60.00 Per Month
            </ListGroupItem>
            <ListGroupItem>
              OFK Unlimited Membership - $120.00 Per Month
            </ListGroupItem>
          </ListGroup>
          <h2>Personal Training</h2>
          <ListGroup>
            <ListGroupItem>
              <strong>30 Mins Consultation</strong>
            </ListGroupItem>
            <ListGroupItem>Members: Free</ListGroupItem>
            <ListGroupItem>Non-members: $25.00</ListGroupItem>
            <ListGroupItem>
              <strong>One Hour Training Session</strong> (prices may vary by trainer)
            </ListGroupItem>
            <ListGroupItem>Members: $55.00</ListGroupItem>
            <ListGroupItem>Non-members: $65.00</ListGroupItem>
            <ListGroupItem>
              <strong>
                Train with Friends and save! Group training now available
              </strong>
            </ListGroupItem>
            <ListGroupItem>2 people: $75.00</ListGroupItem>
            <ListGroupItem>3 people: $85.00</ListGroupItem>
            <ListGroupItem>4 people: $95.00</ListGroupItem>
            <ListGroupItem>5 people: $105.00</ListGroupItem>
          </ListGroup>

          <h2>
            Obstacle 101
            <sub>A 1 hour introduction to the obstacles</sub>
          </h2>

          <ListGroup>
            <ListGroupItem>$35.00 per person</ListGroupItem>
          </ListGroup>

          <h2>Please note</h2>
          <ListGroup>
            <ListGroupItem>
              We only accept cash, CIBC/TD VISA debit, MasterCard and VISA.
            </ListGroupItem>
            <ListGroupItem>1st responders get a 20% discount</ListGroupItem>
            <ListGroupItem>
              Lawyers and bi-Law officers pay double. (jk)
            </ListGroupItem>
            <ListGroupItem>
              Prices may change without notice at any time.
            </ListGroupItem>
            <ListGroupItem>
              All listed prices are subjected to all applicable taxes. Taxes
              added at time of purchase.
            </ListGroupItem>
            <ListGroupItem>
              Gift cards are available on site. Give the fittest gift of all -
              good health!
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
    </Layout>
  )
}

export default Pricing

export const query = graphql`
  {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
